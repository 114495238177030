import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AnToastService } from '@autonation/dt-an-components-lib/an-toast';
import { AnAuthenticationModalService } from '@autonation/dt-an-components-lib/authentication-modal';
import { FavoritesService } from './services/favorites.service';
import { StateService } from './services/state.service';
import { TealiumCallService } from './services/tealium-call.service';
import { AuthActions } from './models/favorite-vehicles';
import { TealiumConfigService } from './services/tealium-config.service';

@Component({
  selector: 'an-save-favorites',
  templateUrl: './an-save-favorites.component.html',
  styleUrls: ['./an-save-favorites.component.scss'],
})
export class AnSaveFavoritesComponent implements OnInit {
  @Input() vin!: string;
  @Input() price!: string;
  @Input() tierId!: string;
  @Input() ariaLabel!: string;
  @Input() vehicleSaved!: boolean;
  @Input() appName!: string;
  @Input() iconWidth!: string;
  @Input() iconHeight!: string;
  @Input() isLoggedIn!: boolean;
  @Input() newIcon!: boolean;
  @Input() newVdpIcon!: boolean;
  @Input() id!: string;
  @Output() onLoading = new EventEmitter<boolean>();
  @Output() doOldSaveVehicleFlow = new EventEmitter<boolean>();
  @Output() onClickHeartIcon = new EventEmitter<{
    isLogedIn: boolean;
    isSaveAction: boolean;
    isNewFlow: boolean;
  }>();

  saved!: boolean;
  sync = false;

  constructor(
    private favoritesService: FavoritesService,
    private toastService: AnToastService,
    private authModalService: AnAuthenticationModalService,
    private stateService: StateService,
    private changeDetectorRef: ChangeDetectorRef,
    private tealiumCallService: TealiumCallService,
    private tealiumConfigService: TealiumConfigService,
  ) {}

  ngOnInit(): void {

    this.setUpTealium();
    // if (this.isFavoritesEnable) {
      this.favoritesService.favoriteVehiclesLoaded.subscribe((loaded) => {
        if (loaded) {
          this.saved = this.favoritesService.isVehicleSaved(this.vin);
          this.changeDetectorRef.detectChanges();
          if(this.newVdpIcon) {
            document.dispatchEvent(
              new CustomEvent("__isSavedtoFav__", {
                detail: this.saved ? '1': '0',
                })
              );
          }
        }
      });
    // }
    // else {
    //   this.saved = this.vehicleSaved;
    //   this.changeDetectorRef.detectChanges();
    // }
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes['vehicleSaved']) {
  //     if (!this.isFavoritesEnable) {
  //       this.saved = changes['vehicleSaved'].currentValue;
  //       this.changeDetectorRef.detectChanges();
  //     }
  //   }
  // }

  // get isFavoritesEnable(): boolean {
  //   return this.stateService.myGarageFavorites?.EnableFavorites || this.stateService.myGarageFavorites?.EnableMyGarageFavorites;
  // }

  clickHeartIcon(): void {
    const logedIn = this.favoritesService.isUserLoggedIn();
    this.onClickHeartIcon.emit({
      isLogedIn: logedIn,
      isSaveAction: !this.saved,
      isNewFlow: true
    });
    document.dispatchEvent(
      new CustomEvent("__isSavedtoFav__", {
        detail: !this.saved ? '1': '0',
        })
      );
    // if (this.isFavoritesEnable) {
      if (logedIn && this.favoritesService.isActiveSession()) {
        if (this.sync) {
          return;
        }
        this.sync = true;

        if (this.favoritesService.favoriteVehicles) {
          this.saved
            ? this.removeVehicleFromFavorites(this.vin)
            : this.saveVehicleToFavorites(this.vin);
        } else {
          this.sync = false;
          this.tealiumCallService.favoritesError(this.appName);
          this.toastService.error();
        }
      } else {
        this.openAuthModal();
      }
    //}
    // else {
    //   if (!this.saved) {
    //     this.doOldSaveVehicleFlow.emit(true);
    //   }
    // }
  }

  saveVehicleToFavorites(vin: string) {
    if (this.favoritesService.isAllowToSave()) {
      this.onLoading.emit(true);
      this.favoritesService.saveVehicle(vin, this.price, this.tierId).subscribe({
        next: (response) => {
          if (response.Success) {
            this.saved = true;
            this.changeDetectorRef.detectChanges();
            this.favoritesService.updateVehiclesCount(
              true,
              vin,
              response.data.saveFavoriteVehicleData.updatedFavoriteCount
            );

            this.toastService.save().subscribe((_) => {
              this.favoritesService.dispatchViewSavedVehicleEvent();
              document.location.href = '/my-account/my-favorite';
            });

            this.favoritesService.favoriteVehiclesLoaded.next(true);
          } else {
            this.toastService.error();
          }

          this.sync = false;
          this.onLoading.emit(false);
        },
        error: (_) => {
          this.sync = false;
          this.onLoading.emit(false);
          this.toastService.error();
        },
      });
    } else {
      this.toastService.limit();
      this.tealiumCallService.maxFavouritesReached(this.appName);
      this.sync = false;
    }
  }

  removeVehicleFromFavorites(vin: string) {
    this.onLoading.emit(true);

    this.favoritesService.deleteVehicle(vin).subscribe({
      next: (response) => {
        if (response.Success) {
          this.saved = false;
          this.changeDetectorRef.detectChanges();
          this.favoritesService.updateVehiclesCount(
            false,
            vin,
            response.data.favoriteVehiclesResponse.updatedFavoriteCount
          );

          this.toastService.remove().subscribe((_) => {
            this.tealiumCallService.favoriteRemovalUndo(this.appName);
            this.saveVehicleToFavorites(vin);
          });

          this.favoritesService.favoriteVehiclesLoaded.next(true);
        } else {
          this.toastService.error({
            title: 'Error Removing from Favorites.',
          });
        }
        this.onLoading.emit(false);
        this.sync = false;
      },
      error: (_) => {
        this.onLoading.emit(false);
        this.sync = false;
        this.toastService.error({
          title: 'Error Removing from Favorites.',
        });
      },
    });
  }

  openAuthModal() {
    this.authModalService.triggerAuthModal().subscribe((doAuthOnClosing:any) => {
      console.log('doAuthOnClosing - ', doAuthOnClosing);
      this.handleTealiumCall(doAuthOnClosing);
      /* istanbul ignore if */
      if (doAuthOnClosing) {
        try {
          localStorage.setItem('trigger_save_vehicle_method', JSON.stringify({vin: this.vin, price: this.price, tier: this.tierId}));
          const wnd = window as any;
          if (wnd.loginWithAuth0) {
            const linkURL = doAuthOnClosing;
            const isRedirect = true;
            const extraParam: string = `mg=f`;
            const prePopulate = false;
            const returnUrl = true;
            const isReturnPage = wnd.location.href;
            /* await */
            wnd.loginWithAuth0(linkURL, isRedirect, extraParam, prePopulate, returnUrl, isReturnPage);

          }          
          else {
            this.toastService.error();
          }
        } catch (e) {
          this.toastService.error();
        }
      } else {
        this.toastService.error();
      }
    });
  }

  setUpTealium() {
    const tealiumAccount = this.stateService.tealiumSettings?.TealiumAccount;
    const tealiumProfile = this.stateService.tealiumSettings?.TealiumProfile;
    const tealiumEnv = this.stateService.tealiumSettings?.TealiumENV;
    if (tealiumAccount && tealiumProfile && tealiumEnv) {
      this.tealiumConfigService.setConfig({
        account: tealiumAccount,
        profile: tealiumProfile,
        environment: tealiumEnv,
      });
    }
  }

  handleTealiumCall(authAction: string) {
    switch (authAction) {
      case AuthActions.Login:
        this.tealiumCallService.signIn(this.appName);
        break;
      case AuthActions.Register:
        this.tealiumCallService.createAccount(this.appName);
        break;
      case '':
        this.tealiumCallService.exitModal(this.appName);
    }
  }

  setCookie(
    name: string,
    value: string,
    expireMinutes: number,
    path = ''
  ): void {
    try {
      const d: Date = new Date();
      d.setTime(d.getTime() + expireMinutes * 60 * 1000);
      const expires = `expires=${d.toUTCString()}`;
      const cpath: string = path ? `; path=${path}` : '';
      document.cookie = `${name}=${value}; ${expires}${cpath}`;
    } catch (ex: any) {
      let msg = 'setCookie failed';
      if (ex && ex.message) { msg += ex.message; }
      console.error(msg);
    }
  }

}
